@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

.letter-n {
  width: 150px;
}

.cls-1 {
  stroke: url(#Degradado_sin_nombre_4-2);
}

.cls-1,
.cls-2 {
  fill: #{$header-background};
  stroke-miterlimit: 10;
  stroke-width: 4px;
}

.cls-2 {
  stroke: url(#Degradado_sin_nombre_4);
}

.bg-menu-color-bar {
  background-image: linear-gradient(to right, #{$gradient-1}, #{$gradient-2}, #{$gradient-3}, #{$gradient-4}, #{$gradient-5});
}

.aptitudes,
.experiences,
.section {
  &-title {
    position: relative;

    &-line {
      position: absolute;
      left: 0;
      bottom: 0;
      height: 4px;
      width: 100%;
    }
  }
}

.footer-heart {
  @include dynamic-color($gradients-colors);
}
