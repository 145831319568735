.menu-color-bar {
  position: absolute;
  bottom: 0px;
  height: 4px;
  width: 100%;
}

.navbar-brand {
  @include media-breakpoint-down('lg') {
    margin: 0 auto;

    & .size-logo-menu {
      max-height: 20px;
    }
  }

  @include media-breakpoint-up('lg') {
    order: 1 !important;

    & .size-logo-menu {
      max-height: 25px;
    }
  }
}

.navbar-collapse {
  @include media-breakpoint-up('lg') {
    order: 2 !important;
  }
}

.form-switch {
  @include media-breakpoint-up('lg') {
    order: 3 !important;
  }
}
