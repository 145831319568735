$font-family-sans-serif: 'Roboto Mono',
monospace;
$max-height-header: 436px;

$container-max-widths: (xxl: 1110px);
/**
COLORS
**/
$gradient-1: #63B4FF !default;
$gradient-2: #5BC9E7 !default;
$gradient-3: #70FFF8 !default;
$gradient-4: #5FE8BB !default;
$gradient-5: #62FF9F !default;
$black-nestordgs: #191919 !default;

$gray-900: #1C1C1C;
$header-background: #1A1A1A;

$gradients-colors: $gradient-1,
$gradient-2,
$gradient-3,
$gradient-4,
$gradient-5;

$theme-colors-theme: ("dark-nestordgs": $black-nestordgs,
  "gradient-nestordgs-1": $gradient-1,
  "gradient-nestordgs-2": $gradient-2,
  "gradient-nestordgs-3": $gradient-3,
  "gradient-nestordgs-4": $gradient-4,
  "gradient-nestordgs-5": $gradient-5,
);

$theme-colors: map-merge($theme-colors, $theme-colors-theme);
