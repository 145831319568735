.aptitudes {
  &-grid {
    justify-content: center;

    @include media-breakpoint-up('sm') {
      grid-template-columns: repeat(auto-fit, minmax(min(100%/2, max(#{$spacer*2}, 100%/3)), 1fr));
    }

    @include media-breakpoint-up('lg') {
      grid-template-columns: repeat(auto-fit, minmax(min(100%/3, max(#{$spacer*2}, 100%/4)), 1fr));
    }

    .aptitude {
      border: #{calc($spacer/12)} solid #{$gray-800};
      height: 150px;
      transition: all ease-in-out 0.2s;
      position: relative;

      &:hover {
        cursor: pointer;

        &::after {
          content: "";
          position: absolute;
          inset: -.09em;
          border: 1px solid;
          @include dynamic-border-color-blur($gradients-colors);
          filter: blur(1em);
        }
      }

      &-bg {
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url("../../../public/assets/aptitudes/bg-aptitudes.png");

        @include media-breakpoint-down('md') {
          padding: #{$spacer};
        }
      }

      &-img {
        aspect-ratio: 3/2;
        object-fit: contain;

        &.difference {
          mix-blend-mode: difference;
        }

        @include media-breakpoint-up('md') {
          aspect-ratio: 7/6;
        }

        @include media-breakpoint-up('md') {
          max-width: #{$spacer*8};
        }
      }
    }

  }
}
