@mixin dynamic-border-color-blur($colors) {
  $hover-color: nth($colors, random(length($colors)));
  border: 1px solid $hover-color !important;
  border-color: $hover-color;
}

@mixin dynamic-color($colors) {
  $hover-color: nth($colors, random(length($colors)));
  color: $hover-color;
}
