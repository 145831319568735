.experiences {
  &-tabs {
    overflow-x: auto;
    gap: 5px;

    & .experience {
      min-width: 200px;

      &:hover {
        cursor: pointer;
      }

      &.active {
        transition: all ease-in-out 0.2s;
        @include dynamic-border-color-blur($gradients-colors);
      }

      &>.btn {
        border: 0px !important;
      }
    }

  }
}

.experience-content {
  & .d-flex {
    @include media-breakpoint-up('lg') {
      min-height: 200px;
    }
  }

  white-space: pre-line;

  &-title {
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../../../public/assets/aptitudes/bg-aptitudes.png");

    &>.title {
      @extend .m-3;

      @include media-breakpoint-up('lg') {
        @include font-size(calc($font-size-base * 3.5));
        margin: calc($spacer * 3.5);
      }
    }
  }

  &-description {
    @extend .px-3;
  }
}


.experience-cls-1 {
  fill: #{$header-background};
}

.experience-cls-2 {
  fill: url(#Degradado_sin_nombre_4-2);
}

.experience-cls-3 {
  fill: url(#Degradado_sin_nombre_4-3);
}

.experience-cls-4 {
  fill: url(#Degradado_sin_nombre_4-4);
}

.experience-cls-5 {
  mix-blend-mode: soft-light;
}

.experience-cls-5,
.experience-title {
  fill: #{$white};
}

.experience-title {
  font-family: RobotoMono-Bold, 'Roboto Mono';
  @include font-size(calc($font-size-base * 4));
}

.experience-cls-7 {
  opacity: .5;
}

.experience-cls-8 {
  fill: url(#Degradado_sin_nombre_4);
}

.stop-one {
  stop-color: #6cf !important;
}

.stop-two {
  stop-color: #{$gradient-5} !important;
}

.bg-grid {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../../public/assets/bg-grid.png');
    background-repeat: no-repeat;
    background-size: cover;
  }

  & .card-body {
    z-index: 1;
  }

}

@mixin animation-mixin($name, $from, $to) {
  @keyframes #{$name} {
    0% {
      transform: translate3d($from, 0, 0);
    }

    100% {
      transform: translate3d($to, 2%, 0);
    }
  }
}

@mixin animation-mixin2($name, $from, $to) {
  @keyframes #{$name} {
    0% {
      transform: translate3d($from, 0%, 0);
    }

    100% {
      transform: translate3d($to, 2%, 0);
    }
  }
}

@include animation-mixin(slide-1, 0%, 2%);

.current-experience:hover {

  & .up-square,
  & .down-square {
    animation: slide-1 .5s infinite;
    animation-direction: alternate;

    &.reverse {
      animation-direction: alternate-reverse;
    }
  }
}
