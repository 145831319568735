.form-switch {
  .form-check-input.language-switch {
    width: 4rem;
  }

  .language-switch {
    appearance: none;
    height: 24px;
    background: linear-gradient(to right, #{$gradient-1}, #{$gradient-2}, #{$gradient-3}, #{$gradient-4}, #{$gradient-5}) !important;
    position: relative;
    border-radius: 25px;
    outline: none;
    border: none;
    box-shadow: none;

    &:focus {
      box-shadow: none;
    }

    &::before {
      content: "";
      @extend .bg-dark-nestordgs;
      height: 1.2rem;
      width: 1.2rem;
      position: absolute;
      border-radius: 50%;
      top: 9%;
      right: 7%;
      transition: all .5s;
      z-index: 2;
    }

    &::after {
      content: attr(data-language);
      position: absolute;
      @extend .text-dark-nestordgs;
      top: 0%;
      left: 15%;
      font-weight: bold;
      transition: all .5s;
      z-index: 1;
    }

    &:checked::before {
      right: 64%;
    }

    &:checked::after {
      left: 53%;
    }
  }
}
